/**
 * 员工卡项消耗次数明细
 */
 import * as API from '@/api/index'

 export default {
     // 获取员工卡项消耗次数明细
     getEmployeeCardTreatNumberReport:params => {
         return API.POST('api/employeeCardTreatNumberReport/list',params)
     },
     // 导出员工卡项消耗次数明细
     exportExcelEmployeeCardTreatNumberReport:params => {
         return API.exportExcel('api/employeeCardTreatNumberReport/excel',params)
     },
 }