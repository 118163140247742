/**
 * Created by jerry on 2020/02/14.
 * 职务api
 */
import * as API from '@/api/index'

export default {
    // 职务列表
    getJobtype: params => {
        return API.POST('api/jobtype/list', params)
    },
    //新增职务
    createJobtype: params => {
        return API.POST('api/jobtype/create', params)
    },
    //编辑职务
    updateJobtype:params => {
        return API.POST('api/jobtype/update', params)
    },
    // 通讯录-员工职位
    getJobJobtypeAll: params => {
        return API.POST('api/jobtype/all', params)
    },
}