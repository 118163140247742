<template>
  <div class="cardTreatNumber content_body">
    <div class="nav_header">
      <el-form :model="EmployeeModel" :inline="true" size="small">
        <el-form-item label="所属门店">
          <el-select v-model="EmployeeModel.EntityID" clearable filterable placeholder="请选择所属门店" :default-first-option="true" @change="handleSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工姓名查找">
          <el-input placeholder="请输入员工姓名查找" v-model="EmployeeModel.EmployeeName" clearable></el-input>
        </el-form-item>
        <el-form-item label="职务筛选">
          <el-select v-model="EmployeeModel.JobID" clearable filterable placeholder="请选择卡职务" :default-first-option="true" @change="handleSearch">
            <el-option v-for="item in jobTypeData" :label="item.JobName" :value="item.ID" :key="item.ID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select v-model="EmployeeModel.CommodityType" @change="handleSearch" clearable @clear="handleSearch">
            <el-option label="项目" value="项目"></el-option>
            <el-option label="储值卡" value="储值卡"></el-option>
            <el-option label="时效卡" value="时效卡"></el-option>
            <el-option label="通用次卡" value="通用次卡"></el-option>
            <el-option label="产品" value="产品"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡名查找">
          <el-input placeholder="请输入员工姓名查找" v-model="EmployeeModel.CardName" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间查询">
          <el-date-picker
            v-model="EmployeeModel.QueryDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handlerExcel" :loading="DownloadLoding">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" :summary-method="getSummaries" show-summary>
      <el-table-column prop="EntityName" label="所属门店"> </el-table-column>
      <el-table-column prop="EmployeeName" label="员工姓名"> </el-table-column>
      <el-table-column prop="JobName" label="职务"> </el-table-column>
      <el-table-column prop="Type" label="商品类型"> </el-table-column>
      <el-table-column prop="CategoryName" label="卡类别"> </el-table-column>
      <el-table-column prop="CardName" label="卡名称"> </el-table-column>
      <el-table-column prop="Quantity" label="消耗次数"> </el-table-column>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="Pagination.total > 0"
        @current-change="handleCurrentChange"
        :current-page.sync="Pagination.page"
        :page-size="Pagination.page_size"
        :layout="Pagination.layout"
        :total="Pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import EntityAPI from "@/api/Report/Common/entity";
import APIJob from "@/api/KHS/Entity/jobtype";
import API from "@/api/Report/Employee/cardTreatNumber";
export default {
  name: "cardTreatNumber",
  components: {},
  props: {},
  data() {
    return {
      DownloadLoding:false, // 导出
      storeEntityList: [], // 门店
      jobTypeData: [], //职务
      tableData: [], // 表格
      Quantity: "", // 合计次数
      EmployeeModel: {
        EntityID: "", // 门店ID
        EmployeeName: "", // 员工姓名
        JobID: "", // 职务ID
        CommodityType: "", // 商品类型
        CardName: "", // 卡名查找
        QueryDate: [new Date(), new Date()], // 时间查询
      },
      Pagination: {
        page: 1, // 当前哪一页
        page_size: 10, // 一页显示几条
        total: 0, // 总条数
        layout: "total, prev, pager, next,jumper",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getstoreEntityList();
    this.getJobType();
    this.search();
  },
  methods: {
    // 触发搜索
    handleSearch() {
      this.Pagination.page = 1;
      this.search();
    },
    // 分页
    handleCurrentChange(page) {
      this.Pagination.page = page;
      this.search();
    },
    // 搜索
    async search() {
      let that = this;
      let params = {
        PageNum: that.Pagination.page,
        Type: that.EmployeeModel.CommodityType,
        CardName: that.EmployeeModel.CardName, //卡名搜索
        EmployeeName: that.EmployeeModel.EmployeeName, //员工姓名搜索
        EntityID: that.EmployeeModel.EntityID, //组织单位ID
        JobID: that.EmployeeModel.JobID, //职务ID
        StartDate: that.EmployeeModel.QueryDate ? that.EmployeeModel.QueryDate[0] : "", //开始时间
        EndDate: that.EmployeeModel.QueryDate ? that.EmployeeModel.QueryDate[1] : "", //结束时间
      };
      let res = await API.getEmployeeCardTreatNumberReport(params);
      if (res.StateCode == 200) {
        that.tableData = res.Data.employeeCardTreatNumberReportOutFroms.List;
        that.Pagination.page_size = res.Data.employeeCardTreatNumberReportOutFroms.PageSize;
        that.Pagination.total = res.Data.employeeCardTreatNumberReportOutFroms.Total;
        that.Quantity = res.Data.employeeCardTreatNumberReportSumOutFrom.Quantity;
      } else {
        that.$message.error(res.Message);
      }
    },
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    // 员工职务
    getJobType: function () {
      var that = this;
      var params = {
        JobTypeName: that.JobTypeName,
      };
      APIJob.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 合计
    getSummaries({ columns }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "Quantity":
            sums[index] = <span class="font_weight_600">{filter_NumFormat(this.Quantity)}</span>;
            break;
        }
      });

      return sums;
    },
    //导出
    handlerExcel(){
    var that = this
      var params = {
        PageNum: that.Pagination.page,
        Type: that.EmployeeModel.CommodityType,
        CardName: that.EmployeeModel.CardName, //卡名搜索
        EmployeeName: that.EmployeeModel.EmployeeName, //员工姓名搜索
        EntityID: that.EmployeeModel.EntityID, //组织单位ID
        JobID: that.EmployeeModel.JobID, //职务ID
        StartDate: that.EmployeeModel.QueryDate ? that.EmployeeModel.QueryDate[0] : "", //开始时间
        EndDate: that.EmployeeModel.QueryDate ? that.EmployeeModel.QueryDate[1] : "", //结束时间
      }
      that.DownloadLoding = true
      API.exportExcelEmployeeCardTreatNumberReport(params).then(res => {
         this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement('a')
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = '员工卡项消耗次数明细.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
      }).finally(() => {
        that.DownloadLoding = false
      })
    },
  },
};
</script>

<style scoped lang="scss">
.cardTreatNumber {
}
</style>
